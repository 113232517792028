import Layout from "views/layout";
import { RouteObject } from "react-router-dom";
import { DiscoverList } from "views/explore";
import { DiscoverListMysql } from "views/explore/mysql_index";


export const discoverRoutes:RouteObject[] = [
    {
        path: "/",
        element: <Layout></Layout>,
        children:[{
            path: "explore-re",
            element:<DiscoverList></DiscoverList>,
        },{
            path: "explore",
            element:<DiscoverListMysql></DiscoverListMysql>,
        }]
    }
]