import { myWorkListItem } from "types/work";
import { PublishedCardActionsStyle } from "./style";
import { Button, Modal, Space, Tooltip, message } from "antd";
import {
  RiAddFill,
  RiDeleteBin2Line,
  RiEdit2Line,
  RiEyeLine,
  RiMoneyCnyCircleLine
} from "react-icons/ri";
import { apiGetWorkDraftDetail } from "api/work";
import { useNavigate } from "react-router-dom";
import { postDeleteWork } from "api/user/work";
import AddAlbum from "components/album/add-album";
import { useState } from "react";
import { getLocalUserInfo } from "utils/functions/common";

function PublishWorkCardActions({ item,shouldRefresh }: { item: myWorkListItem,shouldRefresh:()=>void }) {
    const [isShowAddAlbumModal, setIsShowAddAlbumModal] = useState(false);
    const [detailId, setDetailId] = useState(0);
    const localUser = getLocalUserInfo();
    const navigate = useNavigate();
    const editWork = async (detail: myWorkListItem) => {
        let id  = detail.twinId;
        if ((detail as myWorkListItem).isContest) {
          let res = await apiGetWorkDraftDetail(id);
          if(res.contestWork.contestId === 36){
            navigate(`/contest/longarts/upload/${res.contestWork.contestId}?workId=${id}`);
            return
          }
          if(res.contestWork.contestCode!=='ycwl2'){
            navigate(
              `/g-contest/upload/${res.contestWork.contestId}?workId=${id}`
            );
            return
          }
          navigate(`/contest/upload/${res.contestWork.contestId}?workId=${id}`);
        } else {
          navigate(`/work/update/${id}`);
        }
      };
      const deleteWork = (id: number, state?: number) => {
        Modal.confirm({
          title: "确定删除？",
          content: "删除该作品后，将无法找回",
          okText: "确定",
          cancelText: "取消",
          onCancel() { },
          centered: true,
          onOk() {
              postDeleteWork(id).then(() => {
              shouldRefresh();
              message.success("删除成功");
            });
          },
        });
      };
      const viewWork = (id: number) => {
        window.open(`/work/detail/${id}`, "_blank");
      };
      const addToAlbum = (id: number) => {
        setIsShowAddAlbumModal(true);
        setDetailId(id);
      };
  return (
    <PublishedCardActionsStyle>
      <div className="action-list">
        {item.canEdit === 1 && (
          <Tooltip title="编辑" className="tooltip">
            <Button
              size="large"
              shape="circle"
              onClick={() => editWork(item)}
              style={{ paddingTop: 9 }}
            >
              <RiEdit2Line></RiEdit2Line>
            </Button>
          </Tooltip>
        )}
        {item.canEdit === 1 && <Tooltip title="删除" className="tooltip">
          <Button
            size="large"
            shape="circle"
            style={{ paddingTop: 9 }}
            onClick={() => deleteWork && deleteWork(item.id, item.status)}
          >
            <RiDeleteBin2Line></RiDeleteBin2Line>
          </Button>
        </Tooltip>}   
        {item.onSale !== 0 && !item.isDraft && (
          <Tooltip title="查看" className="tooltip">
            <Button
              onClick={() => viewWork(item.id)}
              size="large"
              shape="circle"
              style={{ paddingTop: 9 }}
            >
              <RiEyeLine></RiEyeLine>
            </Button>
          </Tooltip>
        )}
        {item.status === 2 && item.onSale !== 0 && (
          <Tooltip title="添加至作品集" className="tooltip">
            <Button
              onClick={() => addToAlbum(item.id)}
              size="large"
              shape="circle"
              style={{ paddingTop: 9 }}
            >
              <RiAddFill></RiAddFill>
            </Button>
          </Tooltip>
        )}
         {!item.isContest && item.isPublic === 1 && Boolean(localUser.shopId) && item.onSale ===1 && (
        <Tooltip title="发售作品赚钱收益" className="tooltip">
          <Button
            onClick={() =>
              window.open(
                `/user-center/shop/product/create/?draftId=${item.twinId}`
              )
            }
            size="large"
            shape="circle"
            style={{ paddingTop: 9 }}
          >
            <RiMoneyCnyCircleLine size={20}></RiMoneyCnyCircleLine>
          </Button>
        </Tooltip>
      )}
      </div>
      <Modal
          footer={null}
          closable={false}
          destroyOnClose={true}
          centered={true}
          open={isShowAddAlbumModal}
        >
          <AddAlbum
            id={detailId}
            closeAlbum={() => setIsShowAddAlbumModal(false)}
          />
        </Modal>
    </PublishedCardActionsStyle>
  );
}

export default PublishWorkCardActions;
