import { UserCardStyle } from "./style";
import { Avatar, Button, message, Tooltip } from "antd";
import { postFollowUser } from "api/user";
import { apiPublicUserDetail } from "api/user/detail";
import UserBusinessContact from "components/user-business-contact";
import { useEffect, useState } from "react";
import { RiVipCrown2Fill } from "react-icons/ri";

import { publicUserDetailType } from "types/user/detail";
import { findUserRole } from "utils/functions/common";
import { determineUserLocation } from "utils/functions/user";

type Props = { userId: number, isShowBusinessBtn?: boolean }


function UserCardGlobal({ userId, isShowBusinessBtn }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [useCardDetail, setUserCardDetail] = useState<publicUserDetailType>()
  const [isShow, setIsShow] = useState(false)


  useEffect(() => {
    apiPublicUserDetail(userId).then(res => {
      setUserCardDetail(res)
    })

  }, [userId])

  // 关注用户
  const followUser = () => {
    if (useCardDetail) {
      setIsLoading(true)
      const flag = useCardDetail.isFollow === 1 ? 0 : 1
      postFollowUser(
        {
          followee: userId,
          flag
        }
      ).then(() => {
        useCardDetail.isFollow = flag
        message.success("操作成功")
      }).finally(() => {
        setIsLoading(false)
      })
    }

  }

  const toDetail = () => {
    useCardDetail?.id && window.open(`/user/${useCardDetail?.id}`)
  }

  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
        return "yellow";
      case 3:
        return "blue";
      default:
        return "hide";
    }
  };

  return (
    <UserCardStyle>

      <div className="avatar">
        <a href={`/user/${useCardDetail?.id}`} target='_blank'>
          <Avatar size={78} src={useCardDetail && useCardDetail.avatarUrl} />
        </a>
        {/* <span className="verified">V</span> */}
        <div className="crown">
          <RiVipCrown2Fill size={20} />
        </div>
        {
          useCardDetail?.certifiedRoleId && <Tooltip title={findUserRole(useCardDetail.certifiedRoleId)?.name} placement='bottom'>
            <div className={`role ${renderColor(
              useCardDetail.certifiedRoleId
            )}`}>
              V
            </div>
          </Tooltip>
        }
      </div>

      <div className="wrap">
        <div className="title" onClick={toDetail}>
          <span>{useCardDetail?.username}</span>
        </div>
        <div className="meta">{useCardDetail && determineUserLocation(useCardDetail)}</div>
        <div className="btns">
          <Button size="small" type="primary" className={`btn ${useCardDetail?.isFollow === 1 ? 'active' : ''}`} loading={isLoading} onClick={() => followUser()}>
            {
              useCardDetail?.isFollow === 1 ? '已关注' : '关注'
            }
          </Button>
          {
            Boolean(useCardDetail?.shopId) && <Button size="small" className="btn invite" onClick={() => window.open(`/user/${useCardDetail?.id}/shop`)}>
              TA的店铺
            </Button>
          }
          {!!isShowBusinessBtn && !useCardDetail?.shopId && <Button size="small" className="btn invite" onClick={() => setIsShow(true)}>
            合作邀约
          </Button>
          }

        </div>
      </div>

      {
        useCardDetail && <UserBusinessContact partnerUser={{ name: useCardDetail?.username, id: useCardDetail?.id }} onClose={() => setIsShow(false)} isShow={isShow} />
      }

    </UserCardStyle>
  );
}

export default UserCardGlobal;
