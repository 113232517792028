import styled from "styled-components";

export const PromoteTosellStyle = styled.div`
    .container{
        margin: 0 auto;
        .info-box{
            padding:  ${(props) => props.theme.space.paddingLG}px 0;
            padding-bottom: ${(props) => props.theme.space.paddingLG}px;
            border: 1px solid  ${(props) => props.theme.colors.colorBorder};
            border-radius: ${(props) => props.theme.border.borderRadius}px;
            text-align: left;
            .wrapper{
                max-width: 600px;
                margin: 0 auto;
            }
            .fjs{
                display: flex;
                align-items: center;
                justify-content: space-between;
             
            }
        }
        p {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        button {
            width: 120px;
            padding: 0;
        }
        .back{
            padding-top: ${(props) => props.theme.space.paddingLG}px;
            .countdown{
                margin-top: ${(props) => props.theme.space.paddingLG}px;
                color: ${(props) => props.theme.colors.colorTextTertiary};
            }
        }
    }
`