import { SellerProductStyle } from "./style"
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useEffect, useState } from "react";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { getOtherProductList } from "api/shop";
import { MallProductOtherRecommendListResponse } from "types/product";
import { useParams } from "react-router";

const SellerProduct = ({ userId }: { userId: number }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [list, setList] = useState<MallProductOtherRecommendListResponse[]>([])
    const { productId } = useParams()

    useEffect(() => {
        getPublicProductList()
    }, [userId])

    // 获取卖家店铺
    const getPublicProductList = () => {
        getOtherProductList(Number(productId)).then(res => {
            setList(res)
        })
    }

    const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        slides: {
            perView: 2,
            spacing: 8
        },
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
    });

    useEffect(() => {
        instanceRef && instanceRef.current?.update();
    }, [list])

    const move = (type: string) => {
        if (type === "prev") {
            instanceRef.current?.prev();
        }
        if (type === "next") {
            instanceRef.current?.next();
        }
    };

    const toDetail = (detail: MallProductOtherRecommendListResponse) => {
        window.open(`/product/detail/${detail.id}`)
    }
    return <SellerProductStyle>
        <div ref={sliderRef} className="keen-slider">
            {
                list.length > 0 && list.map((item => {
                    return <div key={item.id}
                        onClick={() => toDetail(item)}
                        className="item keen-slider__slide"
                    >
                        <div className="cover" style={{ backgroundImage: `url(${item.coverUrl})` }} />
                        <div className="title">{item.name}</div>
                        <div className="price">￥{(item.rmbPrice / 100).toFixed(2)}</div>
                    </div>
                }))
            }
        </div>
        {
            list.length > 2 && <>
                <div className="left-arrow" onClick={() => move("prev")}>
                    <RiArrowLeftSLine size={25} />
                </div>
                <div className="right-arrow" onClick={() => move("next")}>
                    <RiArrowRightSLine size={25} />
                </div>
            </>
        }
    </SellerProductStyle>
}

export default SellerProduct