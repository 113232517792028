import { useEffect, useState } from "react"
import { ShopApplicantStyle } from "./style"
import { UserBaseInfo } from "types/user"
import { getUserIdentification } from "api/user"
import { Button, message } from "antd"
import { MallShopDetailResponse } from "types/product"
import ApplicantModal from "./applicant-modal"
import { useNavigate } from "react-router-dom"

const ShopApplicant = ({ detail, updateDetail }: { detail: MallShopDetailResponse, updateDetail: () => void }) => {
    let userInfo: UserBaseInfo = JSON.parse(localStorage.getItem("user_info")!)

    const [isLoading, setIsLoading] = useState(false)

    const [isUserIdentified, setIsUserIdentified] = useState(0)

    const [isShow, setIsShow] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getDetail()
    }, [])

    const getDetail = () => {
        getUserIdentification(Number(userInfo.id)).then(res => {
            setIsUserIdentified(res.status)
        })
    }

    const toAuthentication = () => {
        if (isUserIdentified === 0 || isUserIdentified === 3) {
            window.open(`/user-center/authentication/idCard`)
        }
    }

    const toApplicant = () => {
        if (isUserIdentified !== 2) {
            message.warning("请先完成实名认证再申请开店")
            return
        }
        setIsShow(true)
    }

    return <ShopApplicantStyle>
        <div className="applicant-content">
            <div className="applicant-title">申请开店</div>
            <div className="applicant-tip">店铺功能测试中，申请后请等待官方审核</div>
            <div className="applicant-step">
                <div className="applicant-step-item">
                    <img className="icon icon-one" src="https://cdn-prd.ggac.com/ggac/shop/product/applicant/applicant-icon1.png" />
                    <div className="step-one">步骤一：实名认证</div>
                    <Button type="primary" onClick={toAuthentication} disabled={isUserIdentified !== 0 && isUserIdentified !== 3}>{isUserIdentified === 1 ? '审核中' : isUserIdentified === 2 ? '已认证' : isUserIdentified === 3 ? '已驳回' : '去实名'}</Button>
                </div>
                <div className="applicant-step-item">
                    <img className="icon icon-two" src="https://cdn-prd.ggac.com/ggac/shop/product/applicant/applicant-icon3.png" />
                </div>
                <div className="applicant-step-item">
                    <img className="icon icon-three" src="https://cdn-prd.ggac.com/ggac/shop/product/applicant/applicant-icon2.png" />
                    <div className="step-one">步骤二：申请开店</div>
                    <Button type="primary" loading={isLoading} onClick={toApplicant} disabled={[1, 2].includes(detail.status)}>{[0, 3].includes(detail.status) ? '申请开店' : detail.status === 1 ? '审核中' : detail.status === 2 ? '审核通过' : '禁止申请'}</Button>

                </div>
            </div>
            {
                detail.status === 3 && <div className="wrap">
                    <div className="tip">驳回原因：{detail.refuseReason}</div>
                </div>
            }
            <ApplicantModal isShow={isShow} close={() => setIsShow(false)} success={() => { updateDetail(); setIsShow(false) }} />
        </div>
    </ShopApplicantStyle >
}

export default ShopApplicant