import { Button, Result } from "antd";
import { PromoteTosellStyle } from "./style";
import { activeGetLocalUserInfo } from "utils/functions/common";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

function PromoteToSell() {
  const [remainingSeconds, setRemainingSeconds] = useState(10);
  const [hasShop, setHasShop] = useState(false)
  const [searchParams] = useSearchParams()
  const isContest = Number(searchParams.get('isContest'))
  useEffect(() => {
    if (remainingSeconds <= 0) {
      getBack();
      return;
    }

    const interval = setInterval(() => {
      setRemainingSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingSeconds]);

  useEffect(() => {
    activeGetLocalUserInfo().then((res) => {
      setHasShop(!!res.shopId)
    })
  }, []);
  const { id } = useParams();
  const getBack = () => {
    window.location.href = `/user-center/home/work/draft/list`;
  };
  const extraContent = () => {
    return (
      <PromoteTosellStyle className="PromoteTosellStyle">
        <div className="container">
          <div className="info-box">
            <div className="wrapper">
              <h3>在G站你还可以</h3>
              {
                !!isContest && <>
                  <div className="title">
                    <div className="fjs">
                      <h3>1. 发售商品赚取收益</h3>
                    </div>
                    <p>
                      上传笔刷、源文件、模型、实物、闲置等商品到G站店铺
                      {!hasShop && (
                        <Button type="primary" onClick={() => {
                          window.open(
                            `/user-center/shop/product-list`
                          );
                        }}>
                          立即开店
                        </Button>
                      )}
                      {hasShop && (
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              `/user-center/shop/product/create`
                            );
                          }}
                        >
                          创建商品
                        </Button>
                      )}
                    </p>
                  </div>
                </>
              }
              {
                !isContest && <>
                  <div className="title">
                    <div className="fjs">
                      <h3>1. 发售作品赚取收益</h3>
                      <div className="btn">
                        {!hasShop && (
                          <Button type="primary" onClick={() => {
                            window.open(
                              `/user-center/shop/product-list`
                            );
                          }}>
                            立即开店
                          </Button>
                        )}

                      </div>
                    </div>
                    <p>
                      【授权】一键生成授权商品，出售本作品的使用权限{hasShop && (
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              `/user-center/shop/product/create/?draftId=${id}&code=authorization`
                            );
                          }}
                        >
                          创建授权商品
                        </Button>
                      )}
                    </p>
                    <p>
                      【店铺】创建生成关联商品，支持资源、笔刷、衍生周边等多样商品形式{hasShop && (
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              `/user-center/shop/product/create/?draftId=${id}`
                            );
                          }}
                        >
                          创建普通商品
                        </Button>
                      )}
                    </p>
                  </div>
                </>
              }

              <div className="title">
                <div className="fjs">
                  <h3>2. 前往G站TALK板块八卦一下</h3>
                  <div className="btn">
                    <Button
                      type="primary"
                      onClick={() => {
                        window.open(
                          `/forum/index`
                        );
                      }}
                    >
                      立即前往
                    </Button>
                  </div>
                </div>
                <p>
                  最新行业资讯八卦，等你来看
                </p>
              </div>

            </div>
          </div>

          <div className="back">
            <Button key="buy" onClick={() => getBack()}> 返回个人中心</Button>
            <div className="countdown">{remainingSeconds}s后自动跳转</div>
          </div>
        </div>
      </PromoteTosellStyle>
    );
  };
  return (
    <Result
      status="success"
      title={`${isContest ? '参赛' : ''}作品已提交，很快为你审核哦！`}
      subTitle="分享这幅独特的作品，让更多人看到你的光芒！"
      extra={extraContent()}
    />
  );
}

export default PromoteToSell;
