import { useMenuAnimation } from 'utils/hooks/useMenuAnimation';
import { UploadDorpdownStyle } from './style'



function UploadDorpdown({isOpen}:{isOpen:boolean}) {
 const scope = useMenuAnimation(isOpen,'.dropdown-item');


 const createWorkDraft=async ()=>{
  window.open('/work/create','_blank')
 }

 const onClickGoArticleCreate = () =>{
  window.open('/article/create','_blank')
 }
  return (
    <UploadDorpdownStyle ref={scope}>
      <div className='inner-container'>
        <div className='item-wrap'>
        <div className='dropdown-item' onClick={()=>createWorkDraft()}>上传作品
        </div>
        </div>
        <div className='item-wrap'>
        <div className='dropdown-item' onClick={onClickGoArticleCreate}>上传文章</div>
        </div>
        {/* <div className='item-wrap'>
        <div className='dropdown-item' onClick={()=>navigate('/user-center/home/work/list')}>创作中心</div>
        </div> */}
        </div>
    </UploadDorpdownStyle>
  )
}

export default UploadDorpdown