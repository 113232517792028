import styled from "styled-components";

export const ShopApplicantStyle = styled.div`
    .applicant-content {
        width: 80%;
        background-color: #fff;
        margin: 0 auto;
        padding-bottom: 50px;
        .applicant-title {
            text-align: center;
            font-size: 25px;
            padding: 50px 0 20px;
            font-weight: bold;
        }
        .applicant-tip {
            text-align: center;
            margin-bottom: 20px;
            font-weight: bold;
        }
        .applicant-step {
            display: flex;
            gap: 100px;
            justify-content: center;
            text-align: center;
            
            .icon-one,icon-three {
                height: 60px;
            }

            &-item {
                max-width: 200px;
            }
            
            .icon-two {
                margin-top: 20px;
            }

            .step-one {
                margin: 20px 0;
            }
            button {
                width: 150px;
                height: 40px;
            }
        }
        
    }

    .wrap {
        display: flex;
        justify-content: flex-end;
        margin: 0 50px;
    }
    .tip {
        width: 400px;
        margin-top: 5px;
        text-align: center;
    }
`