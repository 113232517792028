import { Button, Modal, Space, Tooltip, message } from "antd";
import { apiRevertWork, postDeleteDraftWork } from "api/user/work";
import { apiGetWorkDraftDetail } from "api/work";
import {
  RiArrowGoBackLine,
  RiDeleteBin2Line,
  RiEdit2Line,
  RiEyeLine,
  RiMoneyCnyCircleLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { WorkDraftItem } from "types/work";
import { getLocalUserInfo } from "utils/functions/common";
import { DrafWorkCardActionsStyle } from "./style";

function DraftWorkCardAction({
  item,
  shouldRefresh,
}: {
  item: WorkDraftItem;
  shouldRefresh: () => void;
}) {
  const navigate = useNavigate();
  const localUser = getLocalUserInfo();

  const revertWork = (id: number) => {
    apiRevertWork(id).then((res) => {
      shouldRefresh();
      message.success("撤销审核成功");
    });
  };

  const deleteWork = (id: number) => {
    Modal.confirm({
      title: "确定删除？",
      content: "删除该草稿作品后，将无法找回",
      okText: "确定",
      cancelText: "取消",
      onCancel() {},
      centered: true,
      onOk() {
        postDeleteDraftWork(id).then(() => {
          shouldRefresh();
          message.success("删除成功");
        });
      },
    });
  };

  const viewWork = (id: number) => {
    navigate(`/work/preview/${id}`);
  };

  const editWork = async (detail: WorkDraftItem) => {
  
    if (detail.isContest) {
      let res = await apiGetWorkDraftDetail(detail.id);
      if (res.contestWork.contestId === 36) {
        navigate(
          `/contest/longarts/upload/${res.contestWork.contestId}?workId=${detail.id}`
        );
        return;
      }
      if(res.contestWork.contestCode!=='ycwl2'){
        
        window.open(
          `/g-contest/upload/${res.contestWork.contestId}?workId=${detail.id}`
        );
        return
      }

      navigate(
        `/contest/upload/${res.contestWork.contestId}?workId=${detail.id}`
      );
    } else {
      window.open(`/work/update/${detail.id}`);
    }
  };

  return (
    <DrafWorkCardActionsStyle>
    <div className="action-list">
      {item.status === 1 && (
        <Tooltip title="撤销" className="tooltip">
          <Button
            size="large"
            shape="circle"
            onClick={() => revertWork(item.id)}
            style={{ paddingTop: 9 }}
          >
            <RiArrowGoBackLine></RiArrowGoBackLine>
          </Button>
        </Tooltip>
      )}
      <Tooltip title="查看" className="tooltip">
        <Button
          onClick={() => viewWork(item.id)}
          size="large"
          shape="circle"
          style={{ paddingTop: 9 }}
        >
          <RiEyeLine></RiEyeLine>
        </Button>
      </Tooltip>
      {item.canEdit === 1 && (
        <Tooltip title="编辑" className="tooltip">
          <Button
            size="large"
            shape="circle"
            onClick={() => editWork(item)}
            style={{ paddingTop: 9 }}
          >
            <RiEdit2Line></RiEdit2Line>
          </Button>
        </Tooltip>
      )}
      {item.canEdit === 1 && (
        <Tooltip title="删除" className="tooltip">
          <Button
            size="large"
            shape="circle"
            style={{ paddingTop: 9 }}
            onClick={() => deleteWork(item.id)}
          >
            <RiDeleteBin2Line></RiDeleteBin2Line>
          </Button>
        </Tooltip>
      )}
      {!item.isContest && item.isPublic === 1 && Boolean(localUser.shopId) && item.status !==1 &&(
        <Tooltip title="发售作品赚钱收益" className="tooltip">
          <Button
            onClick={() =>
              window.open(
                `/user-center/shop/product/create/?draftId=${item.id}`
              )
            }
            size="large"
            shape="circle"
            style={{ paddingTop: 9 }}
          >
            <RiMoneyCnyCircleLine size={20}></RiMoneyCnyCircleLine>
          </Button>
        </Tooltip>
      )}
    </div>
    </DrafWorkCardActionsStyle>
  );
}

export default DraftWorkCardAction;
