import { useEffect, useState } from "react";
import useScrollPosition from "hooks/useScrollPosition";
import DefaultHeader from "./default";
import MorphHeader from "./morph";
import { store } from "utils/store";
import { changeHeaderType } from "utils/store/reducer/utils";
import { useLocation } from "react-router";
import { Modal } from "antd";
import LoginModal from "components/login-modal";
import { hasToken } from "utils/functions/common";

export const Header = () => {
  const [headerType, setHeaderType] = useState("default");
  const [positionY] = useScrollPosition();
  const navMenuElem = document.getElementById("work-list");

  const [isShowLoginModal, setIsShowLoginModal] = useState(false)

  useEffect(() => {
    const divTop = navMenuElem?.getBoundingClientRect().top;
    if (divTop) {
      let hide = divTop <= 170;
      const type = hide ? "morph" : "default"
      if (type !== headerType) {
        setHeaderType(type);
        store.dispatch(changeHeaderType(type))
      }
    }
  }, [positionY]);

  const location = useLocation()
  useEffect(() => {
    if (!["/home", "/hot", "/recommend", "/follow"].includes(location.pathname)) {
      setHeaderType('default')
    }
  }, [location.pathname])

  const clickShowLoginModal = () => {
    setIsShowLoginModal(true)
  }

  return (
    <div>
      <div style={{ display: headerType === "default" ? 'block' : 'none' }}>
        <DefaultHeader clickShowLoginModal={clickShowLoginModal}></DefaultHeader>
      </div>

      <div style={{ display: headerType === "morph" ? 'block' : 'none' }}>
        <MorphHeader clickShowLoginModal={clickShowLoginModal}></MorphHeader>
      </div>
      <Modal width={450} footer={<></>} title="" destroyOnClose={true} closable={true} onCancel={() => setIsShowLoginModal(false)} centered={true} open={isShowLoginModal}>
        <LoginModal />
      </Modal>
    </div>
  );
};
