import { HeaderStyle } from "./style";
import Logo from "./logo";
import MainMenu from "./menus/menus";
import User from "./user";
import Search from "./search";
import { RiSearchLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router'
import { hasToken } from "utils/functions/common";
import ChatIcon from "./chat";


function DefaultHeader({ clickShowLoginModal }: { clickShowLoginModal: () => void }) {
  const [isShowInput, setIsShowInput] = useState<Boolean>(false);
  const [colorIsBlack, setColorIsBlack] = useState<Boolean>(true)
  const token = hasToken()
  let navigate = useNavigate()

  const hideSearch = () => {
    if (!token) {
      clickShowLoginModal()
      return
    }
    setIsShowInput(true);
  };
  const location = useLocation()

  useEffect(() => {
    const result = ["/hot", "/recommend", "/follow", '/contest/'].find(item => {
      return location.pathname.search(item) !== -1
    })
    if (result || location.pathname === '/home') {
      setColorIsBlack(false)
      return
    }
    setColorIsBlack(true)
  }, [location.pathname])
  const onClickGoHome = () => {
    navigate('/home')
  }
  return (
    <HeaderStyle>
      <div className="wrap">
        <div className={`${colorIsBlack ? 'black' : ''}  header`}>

          <div className="header-left" onClick={onClickGoHome}>
            <Logo></Logo>
          </div>

          <div className="header-center">
            <div style={{ display: !isShowInput ? 'block' : 'none' }}>
              <MainMenu></MainMenu>
            </div>
            {isShowInput && <Search setIsShowInput={setIsShowInput}></Search>}
          </div>

          <div className="header-right">
            {!isShowInput && (
              <div className="search-btn" onClick={() => hideSearch()}>
                <RiSearchLine />
              </div>
            )}
            {/* {hasToken() && !!((userInfo?.isBuyer && userInfo.isBuyer === 1) || (userInfo?.shopId && userInfo.shopId > 0))  && <ChatIcon></ChatIcon>} */}
            {hasToken() && <ChatIcon></ChatIcon>}
            <User />
          </div>
        </div>
      </div>
    </HeaderStyle>
  );
}

export default DefaultHeader;
