import {
  Affix,
  Button,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  Tooltip,
  message,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { WorkUpdateStyle } from "./style";
import ComboUpload from "../components/comboUpload";
import { useEffect, useState } from "react";
import { SecondSelect } from "components/work-category";
import { TagSelect } from "components/tagSelect";
import { ImageCrop } from "components/crop-img";
import { getFileUrlByCosUpload } from "config/cos";
import { getFileName, getLocalUserInfo } from "utils/functions/common";
import { CosDirectoryType } from "types/enums/media-type";
import {
  apiGetWorkDraftDetail,
  createWorkDraft,
  updateWorkDraft,
} from "api/work";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiGetDictList } from "api/common";
import { CreationWare } from "./creationWare";
import { loginPathWithRedirect } from "utils/functions/user";
import AutoPublish from "./autoPublish";
import Collabration, { collabrationUserSimple } from "./collabration";
import { draftWorkDetailType } from "types/work";

type WorkUpdateForm = {
  title: string;
  tagList: string[];
  categoryIds: number[];
  coverUrl: {
    file?: File;
    imgUrl?: string;
  };
  description: string;
  mediaList: { id: number; url: string }[];
  hardwareList?: string[];
  softwareList?: string[];
  mediaCategory: string;
};
type AntSelectOption = {
  label: string;
  value: string | number;
};
function WorkUpdate() {
  const [top] = useState(64);

  const [form] = Form.useForm<WorkUpdateForm>();

  const [coverUrlFile, setCoverUrlFile] = useState<{
    file?: File;
    imgUrl?: string;
  }>();

  const [isPublic, setIsPublic] = useState<number>(1);

  const [loading, setLoading] = useState(false);

  const [mediaCategoryOptions, setMediaCategoryOptions] =
    useState<AntSelectOption[]>();

  const mediaCategory = Form.useWatch("mediaCategory", form);

  const [copyContestWork, setCopyContestWork] = useState<number>(0);

  let navigate = useNavigate();

  let { id } = useParams();

  const exhibitionTag = new URLSearchParams(useLocation().search).get("tag");
  const isShowPrivate = Number(
    new URLSearchParams(useLocation().search).get("isShowPrivate") || 1
  );
  const [creatorList, setCreatorList] = useState<collabrationUserSimple[]>([]);
  const [detail,setDetail] = useState<draftWorkDetailType>()

  useEffect(() => {
    let localUser = getLocalUserInfo();
    if (!localUser) {
      navigate(loginPathWithRedirect());
    }
  }, []);

  useEffect(() => {
    initFormData();
  }, [id, form]);

  useEffect(() => {
    if (!exhibitionTag) return;
    form.setFieldValue("tagList", JSON.parse(exhibitionTag || "[]"));
  }, [exhibitionTag]);

  const initFormData = () => {
    if (!id) {
      form.setFieldsValue({
        title: "",
        tagList: [],
        categoryIds: [],
        coverUrl: {
          file: undefined,
          imgUrl: "",
        },
        description: "",
        mediaCategory: "",
        mediaList: [],
        hardwareList: [],
        softwareList: [],
      });
    } else {
      apiGetWorkDraftDetail(Number(id)).then((res) => {
        setDetail(res)
        setCoverUrlFile({
          file: undefined,
          imgUrl: res.coverUrl,
        });
        setCoverUrlFile({
          file: undefined,
          imgUrl: res.coverUrl,
        });
        setIsPublic(res.isPublic);
        form.setFieldsValue({
          title: res.title,
          tagList: res.tagList?.map((item) => item.name),
          categoryIds: res.categoryList?.map((item) => {
            return item.id;
          }),
          mediaCategory: String(res.mediaCategory),
          coverUrl: {
            file: undefined,
            imgUrl: res.coverUrl,
          },
          description: res.description,
          mediaList: res.mediaList?.map((item) => {
            return {
              id: item.id,
              url: item.url,
              key: item.uuid,
              percent: 100,
              coverUrl: item.coverUrl,
              type: item.type,
              caption: item.caption,
              params: {
                hdrImageUrl: item.params?.hdrImageUrl,
                threeDBgUrl: item.params?.threeDBgUrl,
                specialEffectTemplateId: item.params?.specialEffectTemplateId,
                specialEffectVideo: item.params?.specialEffectVideo,
              },
            };
          }),
          hardwareList: res.hardwareList?.map((item) => item.name),
          softwareList: res.softwareList?.map((item) => item.name),
        });
      });
    }
  };

  useEffect(() => {
    getMediaCategoryOptions();
  }, []);

  useEffect(() => {
    let obj = mediaCategoryOptions?.find(
      (category) => category.value === mediaCategory
    );
    console.log(obj);
  }, [mediaCategory]);

  const getMediaCategoryOptions = () => {
    apiGetDictList({ type: "mediaCategory" }).then((res) => {
      const options = res.map((item) => {
        return { value: String(item.code), label: item.name };
      });
      setMediaCategoryOptions(options);
    });
  };

  const onClickSave = (submit: number, status?: string) => {
    if (!coverUrlFile?.imgUrl) {
      message.warning("请上传封面！");
      return;
    }
    if (loading || coverUrlFile?.imgUrl.includes("data:image")) {
      message.error("有文件正在上传中,请稍后重试！");
      return;
    }

    if (id) {
      form.validateFields().then(async (values) => {
        let {
          title,
          tagList,
          categoryIds,
          description,
          mediaList,
          hardwareList,
          softwareList,
          mediaCategory,
        } = values;
        let mediaListParams = mediaList?.map((item) => {
          return item.id;
        });

        let params = {
          id: Number(id),
          coverUrl: coverUrlFile?.imgUrl!,
          tagList: tagList,
          softwareList: softwareList,
          mediaList: mediaListParams, //展示媒体列表
          description: description, //描述
          title: title, //标题
          categoryId: categoryIds[1], // 类目ID
          hardwareList: hardwareList, // 软硬件标签
          isPublic: isPublic,
          submit: submit,
          mediaCategory: Number(mediaCategory),
          creators:creatorList
        };

        await updateWorkDraft(params);
        if (status === "pre") {
          window.open(`/work/preview/${id}`);
          return;
        }

        if (submit === 1 && isPublic === 1) {
          navigate(`/work/promote-to-sell/${id}`);
        } else {
          navigate("/user-center/home/work/draft/list");
        }

        message.success("操作成功！");
      });
    } else {
      form.validateFields().then(async (values) => {
        let {
          title,
          tagList,
          categoryIds,
          description,
          mediaList,
          hardwareList,
          softwareList,
          mediaCategory,
        } = values;
        let mediaListParams = mediaList?.map((item) => {
          return item.id;
        });

        let params = {
          coverUrl: coverUrlFile?.imgUrl!,
          tagList: tagList,
          softwareList: softwareList?.map((item) => item)!,
          mediaList: mediaListParams, //展示媒体列表
          description: description, //描述
          title: title, //标题
          categoryId: categoryIds[1], // 类目ID
          hardwareList: hardwareList?.map((item) => item)!, // 软硬件标签
          isPublic: isPublic,
          submit: submit,
          mediaCategory: Number(mediaCategory),
          copyContestWork: copyContestWork,
          creators:creatorList
        };
        let draft = await createWorkDraft(params);
        if (status === "pre") {
          navigate(`/work/update/${draft.id}`, { replace: true });
          window.open(`/work/preview/${draft.id}`);
          return;
        }
        message.success("创建草稿成功！");

        if (submit === 1 && isPublic === 1) {
          navigate(`/work/promote-to-sell/${draft.id}`);
        } else {
          navigate("/user-center/home/work/draft/list");
        }
      });
    }
  };

  const upload = async (params: { imgUrl: string; file: File | undefined }) => {
    if (params.file) {
      setLoading(true);
      let urlKey = getFileName(CosDirectoryType.workCover, params.file.name);
      let res = await getFileUrlByCosUpload(params.file!, urlKey);
      setCoverUrlFile({
        file: undefined,
        imgUrl: res.url,
      });
      setLoading(false);
    }
  };

  // const  onClickPublish = async () =>{
  //   await submitWorkDraft(Number(id))
  //   message.success('提交成功！')
  // }

  const onChangeIsPublic = (val: RadioChangeEvent) => {
    setIsPublic(val.target.value);
  };

  const onCopyWorkCheckBoxChange = (flag: number) => {
    setCopyContestWork(flag);
  };

  const canDisplayAutoPublish = () => {
    let obj = mediaCategoryOptions?.find(
      (category) => category.value === mediaCategory
    );
    let flag = false;
    if (obj && ["3D模型", "2D原画", "ANI动画"].includes(obj.label)) {
      flag = true;
    }
    return !id && Boolean(isPublic) && flag;
  };
  return (
    <WorkUpdateStyle>
      <div className="main-container">
        <div className="left">
          <Form
            name="basic"
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            requiredMark={true}
            className="form-area"
            size="large"
            form={form}
          >
            <div className="form-section">
              <Form.Item
                label="作品标题"
                name="title"
                rules={[{ required: true, message: "" }]}
              >
                <Input placeholder="为您的作品起一个好听的名字" />
              </Form.Item>
              <Form.Item
                label="创作类型"
                name="mediaCategory"
                rules={[{ required: true, message: "" }]}
              >
                <Select
                  defaultActiveFirstOption
                  options={mediaCategoryOptions}
                ></Select>
              </Form.Item>
              <Form.Item
                label="内容分类"
                name="categoryIds"
                rules={[{ required: true, message: "类目为必填项" }]}
              >
                <SecondSelect type="work"></SecondSelect>
              </Form.Item>

              <Form.Item label="作品描述" name="description">
                <Input.TextArea />
              </Form.Item>
            </div>
            <div className="form-section">
              <Form.Item
                label="上传作品"
                name="mediaList"
                rules={[{ required: true, message: "作品媒体为必填项" }]}
              >
                <ComboUpload></ComboUpload>
              </Form.Item>
            </div>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.categoryIds !== currentValues.categoryIds
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("categoryIds") ? (
                  <div className="form-section">
                    <Form.Item label="标签" name="tagList">
                      <TagSelect
                        dataTable="work"
                        categoryId={form.getFieldValue("categoryIds")[1]}
                        maxCount={5}
                      ></TagSelect>
                    </Form.Item>
                  </div>
                ) : null
              }
            </Form.Item>
            <div className="form-section">
              <div className="custom-title">创作工具</div>
              <Form.Item label="软件" name="softwareList">
                <CreationWare type={1} selectMode="multiple"></CreationWare>
              </Form.Item>
              <Form.Item label="硬件" name="hardwareList">
                <CreationWare type={2} selectMode="multiple"></CreationWare>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="right">
          <div className="card">
            <div className="header">
              <div>
                <span style={{ color: "red", marginRight: "5px" }}>*</span>
                作品封面
              </div>
            </div>
            <div className="image-crop-wrapper">
              <Spin spinning={loading}>
                <ImageCrop
                  value={coverUrlFile}
                  onChange={(params: {
                    imgUrl: string;
                    file: File | undefined;
                  }) => {
                    setCoverUrlFile({ ...params });
                  }}
                  uploadImage={upload}
                />
              </Spin>
            </div>
            <div className="header">
              <span>共同创作</span>{" "}
              <Tooltip title="添加除本人外的共同创作人或导师，最多可添加5人">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
         <Collabration onListChange={(list)=>setCreatorList(list)} creators={detail?.creators}></Collabration>
          </div>

          <Affix offsetTop={top}>
            <div className="card">
              <div className="header">发布选项</div>

              <div className="footer">
                <div className="choosePublic">
                  {!!(isShowPrivate === 1) && (
                    <Radio.Group
                      onChange={onChangeIsPublic}
                      value={isPublic}
                      optionType="button"
                      buttonStyle="solid"
                    >
                      <Radio value={1}>公开</Radio>
                      <Radio value={0}>私密</Radio>
                    </Radio.Group>
                  )}
                </div>
                <Space direction="vertical" className="block-btn">
                  <Button onClick={() => onClickSave(0, "pre")} block>
                    {" "}
                    预览
                  </Button>
                  <Button onClick={() => onClickSave(0)} block>
                    保存草稿
                  </Button>
                  <Button type="primary" onClick={() => onClickSave(1)} block>
                    立即发布
                  </Button>
                </Space>
              </div>
              {canDisplayAutoPublish() && (
                <div className="card">
                  <AutoPublish
                    onCheckBoxChange={onCopyWorkCheckBoxChange}
                  ></AutoPublish>
                </div>
              )}
            </div>
          </Affix>
        </div>
      </div>
    </WorkUpdateStyle>
  );
}

export default WorkUpdate;
