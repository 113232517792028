import styled from "styled-components";

export const Regultion2024RuleStyle = styled.div`

    @font-face {
        font-family: 'kuaikanshijieti';
        src: url('https://cdn-prd.ggac.com/ggac/font/kuaikanshijieti.ttf');
    }
    @font-face {
        font-family: "DIN";
        src: url("https://cdn.ggac.com/v2/fonts/DINCondensedC-2.e5aeb766.ttf");
    }
    @font-face {
        font-family: 'BebasNeue';
        src: url('https://pre-cdn-hz.ggac.com/v2/fonts/BebasNeue-Regular.efe36cb1.otf');
    }

    .en-rule {
        .title {
            letter-spacing: 0 !important;
        }
        .part1 {
            .list {
                &-item:last-child {
                    text-align: center;
                    padding: 0 25px;
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            .list2 {
                .name {
                    line-height: 25px;
                    margin-bottom: 40px !important;
                }
                .detail {
                    padding: 35px 20px 0;
                }
            }
        }
        .word {
            padding: 0 130px;
        }
        .part2 {
            .list3 {
                .detail {
                    padding: 40px 10px 10px;
                    .name {
                        letter-spacing: 0 !important;
                        font-size: 18px !important;
                    }
                    .desc-wrap {
                        overflow-y: auto;
                        max-height: 240px;
                        ::-webkit-scrollbar {
                          width: 10px; /* 设置滚动条的宽度 */
                          background-color: transparent; /* 滚动条的背景色 */
                        }
                        ::-webkit-scrollbar-track {
                          background: #e1e1e1; /* 轨道的背景色 */
                          border-radius: 10px; /* 轨道的圆角 */
                        }
                        /* 自定义滚动条的滑块（thumb） */
                        ::-webkit-scrollbar-thumb {
                          background-color: #c1c1c1; /* 滑块的背景色 */
                          border-radius: 10px; /* 滑块的圆角 */
                          border: 2px solid #ffffff; /* 滑块边框 */
                        }
                        
                        /* 滑块在悬停或者点击时的样式 */
                        ::-webkit-scrollbar-thumb:hover {
                          background: #a8a8a8; /* 悬停时滑块的背景色 */
                        }
                        
                        ::-webkit-scrollbar-thumb:active {
                          background-color: #919191; /* 点击时滑块的背景色 */
                        }
                    }
                }
            }
        }

        .list4 {
            &-wrap {
                overflow-y: auto;
                .detail {
                    padding-right: 15px;
                }
                ::-webkit-scrollbar {
                  width: 10px; /* 设置滚动条的宽度 */
                  background-color: transparent; /* 滚动条的背景色 */
                }
                ::-webkit-scrollbar-track {
                  background: #e1e1e1; /* 轨道的背景色 */
                  border-radius: 10px; /* 轨道的圆角 */
                }
                /* 自定义滚动条的滑块（thumb） */
                ::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1; /* 滑块的背景色 */
                  border-radius: 10px; /* 滑块的圆角 */
                  border: 2px solid #ffffff; /* 滑块边框 */
                }
                
                /* 滑块在悬停或者点击时的样式 */
                ::-webkit-scrollbar-thumb:hover {
                  background: #a8a8a8; /* 悬停时滑块的背景色 */
                }
                
                ::-webkit-scrollbar-thumb:active {
                  background-color: #919191; /* 点击时滑块的背景色 */
                }
            }
        }

        .list5 {
            &-wrap {
                overflow-y: auto;
                .detail {
                    padding-right: 15px;
                }
                ::-webkit-scrollbar {
                  width: 10px; /* 设置滚动条的宽度 */
                  background-color: transparent; /* 滚动条的背景色 */
                }
                ::-webkit-scrollbar-track {
                  background: #e1e1e1; /* 轨道的背景色 */
                  border-radius: 10px; /* 轨道的圆角 */
                }
                /* 自定义滚动条的滑块（thumb） */
                ::-webkit-scrollbar-thumb {
                  background-color: #c1c1c1; /* 滑块的背景色 */
                  border-radius: 10px; /* 滑块的圆角 */
                  border: 2px solid #ffffff; /* 滑块边框 */
                }
                
                /* 滑块在悬停或者点击时的样式 */
                ::-webkit-scrollbar-thumb:hover {
                  background: #a8a8a8; /* 悬停时滑块的背景色 */
                }
                
                ::-webkit-scrollbar-thumb:active {
                  background-color: #919191; /* 点击时滑块的背景色 */
                }
            }
        }
    }

    .title-wrap {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        .num-wrap {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
            top: -56px;
            height: 56px;
            overflow-y: hidden;
        }
        .num {
            font-size: 70px;
	        color: rgba(46,94,152,0.6);
            font-family: "DIN";
        }
        .color {
            color: rgba(124,165,213,0.6);
        }
        .title {
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regulation-six-title.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 903px;
            height: 83px;
            font-size: 30px;
	        letter-spacing: 12px;
	        color: #ffffff;
            text-align: center;
            font-family: 'kuaikanshijieti';
            line-height: 90px;
        }
    }

    .rule {
        margin-top: -4px;
    }

    .sub-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
	    color: #78c4ff;
        gap: 25px;
        font-family: 'kuaikanshijieti';
        margin-top: 75px;
        margin: 72px 0 18px;
        .sub-content {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .wenhao {
            width: 20px;
            cursor: pointer;
        }
    }

    .part1 {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part1-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 100%;
        padding: 130px 0 325px;
        position: relative;
        z-index: 10;
        .tip {
            font-size: 16px;
	        color: #77c4ff;
            text-align: center;
        }
        .list {
            display: flex;
            justify-content: center;
            gap: 38px;
            margin-top: 40px;
            &-item {
                width: 282px;
                height: 62px;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-btn.png") center center no-repeat;
                background-size: 100% 100%; 
                font-size: 22px;
	            color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                &:hover {
                    background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-btn-active.png") center center no-repeat;
                    background-size: 100% 100%; 
                    color: #ffffff;
                }
            }
        }

        .rule2 {
            margin-top: 170px;
        }

        .list2 {
            display: flex;
            justify-content: center;
            gap: 50px;
            margin-top: 80px;
            .detail {
                width: 262px;
	            height: 253px;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part2-border.png") center center no-repeat;
                background-size: 100% 100%; 
                padding-top: 60px;
                text-align: center;
                .name {
                    font-size: 22px;
	                letter-spacing: 4px;
	                color: #ffffff;
                    margin-bottom: 55px;
                    padding-left: 5px;
                }
                .desc {
                    font-size: 14px;
	                line-height: 24px;
	                color: #ffffff;
                }
            }
        }
    }

    .part2 {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part2-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 100%;
        padding: 430px 0 325px;
        position: relative;
        z-index: 9;
        margin-top: -280px;
        .list-wrap {
            margin-bottom: 150px;
            .word {
                text-align: center;
                color: rgba(46, 94, 152);
                margin-top: ${props => props.theme.space.marginLG}px;
                font-size: 16px;
            }
        }
        .list3{
            display: flex;
            justify-content: center;
            gap: 50px;
            margin: 80px 0 0;
            .detail {
                width: 252px;
	            height: 373px;
                background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part3-border.png") center center no-repeat;
                background-size: 100% 100%; 
                padding-top: 40px;
                text-align: center;
                .name {
                    font-size: 22px;
	                letter-spacing: 4px;
	                color: #ffffff;
                    margin-bottom: 40px;
                    padding-left: 5px;
                }
                .desc {
                    font-size: 14px;
	                line-height: 24px;
	                color: #ffffff;
                }
            }
        }
        .list4 {
            display: flex;
            flex-direction: column;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part4-border.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 1400px;
            height: 1279px;
            margin: 80px auto 0;
            padding: 120px 50px 110px;
            .detail {
                display: flex;
                font-size: 18px;
	            line-height: 28px;
	            color: #ffffff;
                &>div {
                    margin-top: 20px;
                }
            }
        }
    }

    .part3 {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part3-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 100%;
        padding: 350px 0 400px;
        position: relative;
        z-index: 8;
        margin-top: -280px;
        .list5 {
            display: flex;
            flex-direction: column;
            background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part5-border.png") center center no-repeat;
            background-size: 100% 100%; 
            width: 1400px;
            height: 1019px;
            margin: 80px auto 0;
            padding: 140px 90px 110px;
            .detail {
                display: flex;
                font-size: 18px;
	            line-height: 28px;
	            color: #ffffff;
                &>div {
                    margin-top: 20px;
                }
            }
        }
    }

    .part4 {
        background: url("https://cdn-prd.ggac.com/ggac/reg/2024/regultion-six-rule-part4-bg.png") center center no-repeat;
        background-size: 100% 100%; 
        width: 100%;
        padding: 430px 0 100px;
        position: relative;
        z-index: 7;
        margin-top: -280px;
        text-align: center;
    }
`