import styled from "styled-components";

export const SellerProductStyle = styled.div`
    position: relative;
    .item {
        .cover {
            width: 100%;
            height: 100px;
            border-radius: ${props => props.theme.border.borderRadius}px;
            background-size: cover;
            cursor: pointer;
        }
        .title {
            word-break: break-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            margin-top: ${props => props.theme.space.margin}px;
            padding: 0 ${props => props.theme.space.paddingXXS}px;
        }
        .price {
            font-size: 14px;
            font-weight: 500;
            color: #202020;
        }
    }
    .left-arrow {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: -35px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
    .right-arrow {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        right: -35px;
        cursor: pointer;
        padding: ${props => props.theme.space.paddingXS}px;
    }
`