import { PublicProductDetail } from 'types/product'
import { RichTextContentStyle } from './style'
import { useEffect, useState } from 'react'

function RichTextContent({ detail }: { detail: PublicProductDetail }) {
  const [manuscriptParams, setManuscriptParams] = useState({
    creationType: "",
    themeStyle: "",
    workType: "",
    goodAtSoftware: ""
  })
  useEffect(() => {
    if (detail && detail.manuscriptParams) {
      const result = JSON.parse(detail.manuscriptParams)
      result.themeStyle = result.themeStyle.replaceAll(",", "，")
      console.log(result)
      setManuscriptParams(result)
    }
  }, [detail])
  return (
    <RichTextContentStyle>
      {
        !!detail.manuscriptParams && <div className='list'>
          <div style={{ fontWeight: 'bold' }}>稿件参数</div>
          <div>
            <span>作品类型:</span>
            <span>{manuscriptParams.workType.replaceAll(",", "，")}</span>
          </div>
          <div>
            <span>题材风格:</span>
            <span>{manuscriptParams.themeStyle.replaceAll(",", "，")}</span>
          </div>
          {
            manuscriptParams.goodAtSoftware && <div>
              <span>擅长软件:</span>
              <span>{manuscriptParams.goodAtSoftware.replaceAll(",", "，")}</span>
            </div>
          }
        </div>
      }
      <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: detail?.description! }}
      ></div>
    </RichTextContentStyle>
  )
}

export default RichTextContent