import * as Avatar from "@radix-ui/react-avatar";
import { UiAvatarStyle } from "./style";
import { findUserRole } from "utils/functions/common";
import { Tooltip } from "antd";

type Props = {
  url: string;
  username?: string;
  certifiedRoleId?: number;
  size?: number;
};
const UiAvatar = ({ url, username, certifiedRoleId ,size=120}: Props) => {
  const renderColor = (num: number | undefined) => {
    switch (num) {
      case 1:
       return 'yellow'

      case 3:
        return 'blue'

      default:
        return 'hide'
    }
  };

  return (
    <UiAvatarStyle size={size}>
      <Avatar.Root className="AvatarRoot" >
        <Avatar.Image className="AvatarImage" src={url} alt=""/>
        <Avatar.Fallback className="AvatarFallback" delayMs={600}>
          {username && username.substring(0, 2)}
        </Avatar.Fallback>
        {certifiedRoleId && (
          <Tooltip
            title={findUserRole(certifiedRoleId)?.name}
            placement="bottom"
          >
            <div className={`role ${renderColor(findUserRole(certifiedRoleId)?.value)}`}>
            V
            </div>

          </Tooltip>
        )}
      </Avatar.Root>
    </UiAvatarStyle>
  );
};

export default UiAvatar;
